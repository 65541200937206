<template>
  <div>
    <!-- begin::Entry -->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid py-8">
        <!--begin::Education-->
        <div class="d-flex flex-row">
          <UserNav activePage="bestpracticies" activeTab="4"></UserNav>
          <!--begin::Content-->
          <div class="flex-row-fluid ml-lg-8">

            <div class="pb-10">
              <!--begin::Section-->
              <div>
                <div class="card">
                  <div class="card-body">
                    <div>
                      <h3 class="font-weight-bolder text-dark fs-15 py-5">Troubleshooting</h3>
                      <div class="">
                        <b-card no-body>
                          <b-tabs pills card vertical>
                            <b-tab title="Troubleshooting" active>
                              <b-card-text>
                                <div>
                                  To troubleshoot common issues in Streamlabs Desktop, here’s a step-by-step guide
                                  that covers system updates, app updates, and downgrading to a previous version on
                                  both Windows and macOS.
                                  <br><br>
                                  <h2 class="font-size-h4 fw-bold-header"> Step 1: Check for Operating System Updates
                                  </h2>
                                  <br>
                                  Outdated operating systems can cause compatibility issues with Streamlabs Desktop.
                                  Here’s how to ensure your OS is up to date:
                                  <br><br>
                                  <span class="fw-bold">Windows</span>
                                  <br>
                                  1. <span class="bodyheaderstyle">Open Settings</span>: Press `Windows + I` to open the
                                  Settings menu.<br>
                                  2. <span class="bodyheaderstyle">Go to Updates</span>: Click on <span
                                    class="bodyheaderstyle">Update & Security</span>, then select *<span
                                    class="bodyheaderstyle">Windows
                                    Update</span>.<br>
                                  3. <span class="bodyheaderstyle">Check for Updates</span>: Click <span
                                    class="bodyheaderstyle">Check for updates</span>. If updates are available,
                                  they’ll start downloading automatically. If prompted, click <span
                                    class="bodyheaderstyle">Restart now</span> to
                                  complete the installation.<br>
                                  4. <span class="bodyheaderstyle">Restart Your Computer</span>: After updates are
                                  installed, restart your computer to
                                  apply changes.<br>
                                  <br>
                                  <span class="fw-bold">macOS</span><br>
                                  1. <span class="bodyheaderstyle">Open System Preferences</span>: Click the Apple icon
                                  in the top-left corner, then
                                  select <span class="bodyheaderstyle">System Preferences</span>.<br>
                                  2. <span class="bodyheaderstyle">Go to Software Update</span>: Click <span
                                    class="bodyheaderstyle">Software Update</span>. macOS will check for any
                                  available updates.<br>
                                  3. <span class="bodyheaderstyle">Install Updates</span>: If updates are available,
                                  click <span class="bodyheaderstyle">Update Now</span> and follow
                                  the prompts. You may need to enter your password and restart your Mac once
                                  installation is complete.<br>
                                  <br>
                                  <h2 class="font-size-h4 fw-bold-header"> Step 2: Update Streamlabs Desktop</h2>
                                  Updating Streamlabs Desktop can fix bugs, add new features, and improve
                                  performance.<br>

                                  1. <span class="bodyheaderstyle">Open Streamlabs Desktop</span>: Launch the Streamlabs
                                  Desktop application.<br>
                                  2. <span class="bodyheaderstyle">Check for Updates</span>: On Windows, Streamlabs
                                  usually prompts for updates at
                                  launch if available. However, if you don’t see a prompt, you can download the latest
                                  version from the [Streamlabs website](<a href="https://streamlabs.com/"
                                    target="_blank">https://streamlabs.com/</a>) and reinstall it.<br>
                                  3. <span class="bodyheaderstyle">Reinstall (If Needed)</span>: Downloading the latest
                                  version and reinstalling over
                                  your existing installation won’t remove your settings. Simply install it as you
                                  would normally.
                                  <br><br>
                                  <h2 class="font-size-h4 fw-bold-header"> Step 3: Troubleshoot Common Issues</h2>
                                  If you’re still experiencing issues, here are some common troubleshooting tips:<br>

                                  1. <span class="bodyheaderstyle">Run as Administrator (Windows Only)</span>:
                                  - Right-click the Streamlabs Desktop icon and select <span class="bodyheaderstyle">Run
                                    as administrator</span>. This
                                  can fix permission-related issues.<br>

                                  2. <span class="bodyheaderstyle">Check for Missing Permissions (macOS Only)</span>:
                                  - Go to <span class="bodyheaderstyle">System Preferences > Security & Privacy >
                                    Privacy</span>, then under <span class="bodyheaderstyle">Screen
                                    Recording</span> and <span class="bodyheaderstyle">Microphone</span>, make sure
                                  Streamlabs Desktop is allowed.<br>

                                  3. <span class="bodyheaderstyle">Disable Other Overlays</span>:
                                  - Apps like Discord, Xbox Game Bar, or GeForce Experience can cause conflicts with
                                  Streamlabs. Disable any overlays from these apps.<br>

                                  4. <span class="bodyheaderstyle">Lower Graphics Settings</span>:
                                  - Go to <span class="bodyheaderstyle">Settings > Video</span> in Streamlabs Desktop
                                  and lower the resolution or frame
                                  rate if experiencing performance issues.<br>
                                  <br>
                                  <!-- <h2 class="font-size-h4 fw-bold-header"> Step 4: Roll Back to a Previous Version of
                                    Streamlabs Desktop</h2>
                                  Sometimes, newer versions of Streamlabs Desktop may introduce bugs. If updating
                                  didn’t help, try downgrading to a previous version.<br>

                                  1. <span class="bodyheaderstyle">Uninstall Streamlabs Desktop</span>:<br>
                                  - On <span class="bodyheaderstyle">Windows</span>: Open <span
                                    class="bodyheaderstyle">Settings > Apps</span>, locate <span
                                    class="bodyheaderstyle">Streamlabs Desktop</span>, and click
                                  <span class="bodyheaderstyle">Uninstall</span>.<br>
                                  - On <span class="bodyheaderstyle">macOS</span>: Go to <span
                                    class="bodyheaderstyle">Applications</span> in Finder, locate Streamlabs Desktop,
                                  and
                                  drag it to the Trash. Then, empty the Trash.<br>

                                  2. <span class="bodyheaderstyle">Download a Previous Version</span>:<br>
                                  - Visit the [Streamlabs OBS GitHub
                                  repository](<a href="https://github.com/stream-labs/streamlabs-obs/releases"
                                    target="_blank">https://github.com/stream-labs/streamlabs-obs/releases</a>) to
                                  download
                                  older versions of Streamlabs Desktop.<br>
                                  - Select a stable release prior to the current version to minimize bugs.<br>

                                  3. <span class="bodyheaderstyle">Install the Previous Version</span>:<br>
                                  - Download and open the installation file. Follow the installation steps as you
                                  normally would.<br>

                                  4. <span class="bodyheaderstyle">Restore Settings</span>: Your Streamlabs profile
                                  settings should remain in place,
                                  but you may need to reconfigure certain scenes or sources.<br>
                                  <br> -->
                                  <h2 class="font-size-h4 fw-bold-header"> Step 4: Contact Support (If Issues Persist)
                                  </h2>
                                  If these steps don’t resolve your issue, consider contacting Streamlabs support:<br>
                                  1. <span class="bodyheaderstyle">Go to the Help Center</span>: Visit the [Streamlabs
                                  Support<br>
                                  page](<a href="https://support.streamlabs.com/"
                                    target="_blank">https://support.streamlabs.com/</a>) for articles and contact
                                  options.<br>
                                  2. <span class="bodyheaderstyle">Submit a Ticket</span>: If troubleshooting articles
                                  don’t help, submit a ticket or
                                  reach out via their official Discord server for real-time assistance.<br>
                                  <br>
                                  Following these steps should help you troubleshoot most Streamlabs Desktop issues
                                  effectively.
                                  <br><br>
                                </div>
                              </b-card-text>
                            </b-tab>
                            <b-tab title="Bitrate"><b-card-text>
                                <div>
                                  <h2 class="font-size-h4">Dynamic Bitrate</h2>
                                  <p>
                                    In the latest update to Streamlabs OBS, they included a
                                    feature called Dynamic Bitrate. This feature allows
                                    their software to automatically adjusts your bitrate
                                    based on network conditions to prevent dropping
                                    frames.
                                  </p>
                                  <figure>
                                    <div>
                                      <div>
                                        <iframe class="s t u ey aj" title="Dynamic Bitrate" src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com
                              %2Fembed%2FAQiMqO-aSoI%3Ffeature%3Doembed&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch
                              %3Fv%3DAQiMqO-aSoI&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FAQiMqO-aSoI%2Fhqdefault.jpg
                              &amp;key=a19fcc184b9711e1b4764040d3dc5c07&amp;type=text%2Fhtml&amp;schema=youtube"
                                          frameborder="0" scrolling="auto" allowfullscreen=""></iframe>
                                      </div>
                                    </div>
                                  </figure>
                                  <p>
                                    Many external factors can affect a user’s connection.
                                    Network congestion is the main culprit. Having a
                                    static bitrate can cause frames to drop because your
                                    connection is unable to keep up and will drop frames
                                    to improve the stability of your stream and minimize
                                    latency.
                                  </p>
                                  <p>
                                    Several reasons can cause network congestion. An
                                    unstable connection to your ISP or streaming over wifi
                                    is most common. Dynamic Bitrate combats this by
                                    automatically adjusting your bitrate when you have
                                    issues with your internet connection.
                                  </p>
                                  <h2><strong>Here’s how it works:</strong></h2>
                                  <ol>
                                    <li>Set a “target bitrate”.</li>
                                    <li>
                                      When you experience network issues, Streamlabs OBS
                                      will lower your bitrate until you’re not dropping
                                      frames anymore.
                                    </li>
                                    <li>
                                      Once we detect that the network issues are gone,
                                      your bitrate should gradually go back up to the
                                      target bitrate you had set.
                                    </li>
                                  </ol>
                                  <h2><strong>How to enable Dynamic Bitrate:</strong></h2>
                                  <p>
                                    To enable Dynamic Bitrate, make sure you download the
                                    latest version of Streamlabs OBS. Navigate to Settings
                                    &gt; Advanced and toggle the box next to the option
                                    that says “Dynamically change bitrate when dropping
                                    frames while streaming”.
                                  </p>
                                  <figure>
                                    <div class="mw-60">
                                      <img class="w100 h100"
                                        src="https://ingomucdn.s3.amazonaws.com/image/png/0_0jtsaNx66VkjtKgN.png"
                                        alt="0*0jtsaNx66VkjtKgN.png" />
                                    </div>
                                  </figure>
                                  <p>
                                    You can download
                                    <a href="https://streamlabs.com/slobs/download" target="_blank"
                                      rel="noreferrer noopener">Streamlabs OBS</a>
                                    here.
                                  </p>
                                </div>
                              </b-card-text>
                            </b-tab>
                            <b-tab title="Portrait Mode"><b-card-text>
                                <div>
                                  <h2 class="font-size-h4">Portait Mode</h2>
                                  <p>
                                    Here is a helpful video tutorial that wil guided you through the process of setting
                                    up
                                    portrait mode in Streamlabs OBS.
                                  </p>
                                  <div style="width: 70%; height: auto;">
                                    <video ref="videoPlayer" class="video-js"></video>
                                  </div>
                                </div>
                              </b-card-text>
                            </b-tab>
                          </b-tabs>
                        </b-card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Section-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
import UserNav from "@/view/layout/header/UserNav.vue";

export default {
  name: "troubleshooting",
  components: {
    UserNav
  },
  data() {
    return {
      player: null,
      videoOptions: {
        autoplay: false,
        controls: true,
        muted: true,
        height: "200",
        width: "200",
        aspectRatio: "16:9",
        responsive: true,
        fluid: true,
        sources: [
          {
            src:
              'https://ingomucdn.s3.amazonaws.com/video/Portrait.mp4',
            type: 'video/mp4'
          }
        ]
      }
    }
  },
  mounted() {
    this.$store.commit("CHANGE_TABINDEX", { tabIndex: 3 });
    this.$store.commit("CHANGE_ACTIVETAB", { activeTab: 12 });

    this.player = videojs(this.$refs.videoPlayer, this.videoOptions);
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
};
</script>

<style scoped>
.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.mw-60 {
  max-width: 60% !important;
}

.bodyheaderstyle {
  font-weight: bold;
}

.fw-bold-header {
  font-weight: bold;
  font-size: larger;
}

.fw-bold {
  font-weight: bold;
}
</style>